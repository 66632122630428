import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col, Button } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from "../components/Project/project.module.css"

import ProjectWaterSection from "../components/Project/ProjectWaterSection"
import ProjectTransBlock from "../components/Project/ProjectTransBlock"
import ProjectTabs from "../components/Project/ProjectTabs"
import PortablePoolSignupForm from "../components/PortablePoolModal/PortablePoolSignupForm"

const SwimLessonsRegistration = ({data}) => (
    <Layout>
    <SEO title="Portable Pool Swim Lessons Registration" />
    <Container>
        <Row className="mt-4">
            <Col>
                <h1 style={{ color: '#003366'}}>Summer 2021 Portable Pool Swim Lesson Application</h1>
            </Col>
        </Row>
        <Row className="py-4">
            <Col>
            <h2>The application period has ended.</h2>
            {/* <PortablePoolSignupForm />     */}
            </Col>
        </Row>
        
        </Container>
    </Layout>
)

export default SwimLessonsRegistration;